// row_click_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row"];

  connect() {
    this.rowTargets.forEach((row) => {
      row.addEventListener("click", this.handleRowClick);
    });
  }

  disconnect() {
    this.rowTargets.forEach((row) => {
      row.removeEventListener("click", this.handleRowClick);
    });
  }

  handleRowClick(event) {
    const url = event.currentTarget.dataset.url;
    if (url) {
      event.preventDefault();
      window.Turbo.visit(url);
    }
  }
}
