// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// import $ from 'jquery'

import Rails from '@rails/ujs'
import { Turbo } from  "@hotwired/turbo-rails"
import("channels")
import $ from 'jquery'
import RowClickController from "../controllers/row_click_controller"

// import('src/boostrap_deps')

// import('src/bootstrap_deps.js').then(() => {
//   $('body').tooltip({selector: '[data-toggle="tooltip"]'});
// })
// import('popper.js').then(() => {
//   $('body').tooltip({selector: '[data-toggle="tooltip"]'});
// })
import('jquery-slimscroll/jquery.slimscroll')
// import * as bootstrap from 'bootstrap'

// import('bootstrap-sass/assets/javascripts/bootstrap')
// import('bootstrap/dist/js/bootstrap')
// import('bootstrap-select/dist/js/bootstrap-select')

const bootstrap = window.bootstrap = require('bootstrap');
require('bootstrap-select/dist/js/bootstrap-select')
require('beyondAdmin/js/beyond')
require('datatables.net-bs5')(window, $)
// require('datatables.net')(window, $)
// import dt from "datatables.net";

// require('datatables-bootstrap3-plugin/media/js/datatables-bootstrap3')
// require('datatables-responsive/js/dataTables.responsive')
// require('datatables.net-fixedheader-bs')( window, $ );
// import('beyondAdmin/js/charts/easypiechart/jquery.easypiechart')
// import { Turbo } from  "@hotwired/turbo-rails"
// import { tempusdominus } from "../vendor/tempus-dominus"
import { TempusDominus } from '../vendor/tempus-dominus.esm'

Rails.start()
window.Turbo = Turbo
window.Rails = Rails

import("controllers")
import '../images/favicon.ico'
import('../images/logo.png')
import '../stylesheets/application.scss'
import('../images/nestle-logo.png')
import('../images/pepsi-logo-resize.png')

application.register("row-click", RowClickController);

document.addEventListener("turbo:load", () => {
  console.log('turbo:load')

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (el) {
    return new bootstrap.Tooltip(el)
  })
})

document.addEventListener("turbo:before-fetch-response", () => {
  console.log('turbo:before-fetch-response')

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (el) {
    return new bootstrap.Tooltip(el)
  })
})

document.addEventListener("turbo:load", () => {
  // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  //   return new bootstrap.Tooltip(tooltipTriggerEl)
  // })

  // $(function () {
  // })
  // $('body').tooltip({selector: '[data-toggle="tooltip"]'})

  $.extend( true, $.fn.dataTable.defaults, {
    pageLength: 100,
    dom: "<'row'<'col-sm-6'<'pull-left'f>><'col-sm-6'<'pull-right'l>>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-6'<'pull-left'i>><'col-sm-6'<'pull-right'p>>>",
  } );

  function getcolor(colorString) {
    switch (colorString) {
      case "themeprimary":
        return themeprimary
      case "themesecondary":
        return themesecondary
      case "themethirdcolor":
        return themethirdcolor
      case "themefourthcolor":
        return themefourthcolor
      case "themefifthcolor":
        return themefifthcolor
      default:
        return colorString
    }
  }

  var InitiateEasyPieChart = function () {
    return {
        init: function () {
            var easypiecharts = $('[data-toggle=easypiechart]');
            $.each(easypiecharts, function () {
                var barColor = getcolor($(this).data('barcolor')) || themeprimary,
                    trackColor = getcolor($(this).data('trackcolor')) || false,
                    scaleColor = getcolor($(this).data('scalecolor')) || false,
                    lineCap = $(this).data('linecap') || "round",
                    lineWidth = $(this).data('linewidth') || 3,
                    size = $(this).data('size') || 110,
                    animate = $(this).data('animate') || false;

                $(this).easyPieChart({
                    barColor: barColor,
                    trackColor: trackColor,
                    scaleColor: scaleColor,
                    lineCap: lineCap,
                    lineWidth: lineWidth,
                    size: size,
                    animate : animate
                });
            });
        }
    };
  }();

  // InitiateEasyPieChart.init()
})

$(document).on("turbo:submit-end", function(event){
  event.detail.formSubmission.formElement.remove()
})

// $(document).on("turbolinks:load", function() {
//   $('.date_time_picker > .input-group.date').datetimepicker()
// })

$(document).on("turbo:load", function() {
  document.querySelectorAll('.datetime').forEach((element) => {
    new TempusDominus(element)
  })
//   {
//     display: {
//             components: {
//                 calendar: true,
//                 date: true,
//                 month: true,
//                 year: true,
//                 decades: true,
//                 clock: true,
//                 hours: true,
//                 minutes: true,
//                 seconds: false,
//                 useTwentyfourHour: false,
//             }
//     }
//  }
})
